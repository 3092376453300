var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeaderDisplay', {
    attrs: {
      "title": "".concat(_vm.capitalize(_vm.$route.query.type || _vm.importData.type), " Import"),
      "sub-title": "Import ".concat(_vm.capitalize(_vm.$route.query.type || _vm.importData.type), " data from a CSV file directly into Etainabl by following the steps below"),
      "category": "Import Management",
      "category-logo": "fa-file-import",
      "category-route": {
        name: 'import-list'
      }
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "wizard-step",
    class: {
      active: _vm.$route.name === 'import-upload',
      completed: _vm.completed.upload
    }
  }, [_c('div', {
    staticClass: "text-muted text-uppercase font-w600"
  }, [_vm._v(" Step 1 "), _vm.completed.upload ? _c('i', {
    staticClass: "fa-duotone fa-circle-check text-success mr-2"
  }) : _vm._e()]), _vm._m(0)])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "wizard-step",
    class: {
      active: _vm.$route.name === 'import-mapping',
      completed: _vm.completed.mapping
    }
  }, [_c('div', {
    staticClass: "text-muted text-uppercase font-w600"
  }, [_vm._v(" Step 2 "), _vm.completed.mapping ? _c('i', {
    staticClass: "fa-duotone fa-circle-check text-success mr-2"
  }) : _vm._e()]), _vm._m(1)])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "wizard-step",
    class: {
      active: _vm.$route.name === 'import-preview',
      completed: _vm.completed.preview
    }
  }, [_c('div', {
    staticClass: "text-muted text-uppercase font-w600"
  }, [_vm._v(" Step 3 "), _vm.completed.preview ? _c('i', {
    staticClass: "fa-duotone fa-circle-check text-success mr-2"
  }) : _vm._e()]), _vm._m(2)])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "wizard-step",
    class: {
      active: _vm.$route.name === 'import-import'
    }
  }, [_c('div', {
    staticClass: "text-muted text-uppercase font-w600"
  }, [_vm._v(" Step 4 "), _vm.completed.import ? _c('i', {
    staticClass: "fa-duotone fa-circle-check text-success mr-2"
  }) : _vm._e()]), _vm._m(3)])])]), _c('router-view')], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', {
    staticClass: "font-size-h4"
  }, [_c('i', {
    staticClass: "fa-duotone fa-upload mr-2"
  }), _vm._v("Upload")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', {
    staticClass: "font-size-h4"
  }, [_c('i', {
    staticClass: "fa-duotone fa-diagram-next mr-2"
  }), _vm._v("Mapping")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', {
    staticClass: "font-size-h4"
  }, [_c('i', {
    staticClass: "fa-duotone fa-magnifying-glass mr-2"
  }), _vm._v("Preview")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', {
    staticClass: "font-size-h4"
  }, [_c('i', {
    staticClass: "fa-duotone fa-file-import mr-2"
  }), _vm._v("Import")]);
}]

export { render, staticRenderFns }